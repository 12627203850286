import { get } from 'svelte/store';
import { projectMetadata as ProjectMetadata } from './store';
import { handleSnackbar } from './store';
export var copyToClipboard = function (what) {
    var text = document.createRange();
    text.selectNode(document.getElementById(what));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(text);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    what = what
        .split('-')
        .map(function (word) { return word.charAt(0).toUpperCase() + word.substring(1); })
        .join(' ');
    handleSnackbar.set({
        isSnackbar: true,
        message: what + " copied successfully!"
    });
};
export var copyHowToCite = function () {
    copyToClipboard('how-to-cite');
};
export var copyPermalink = function () {
    copyToClipboard('permalink');
};
export function findPersonByID(id) {
    var persons = get(ProjectMetadata).persons;
    if (persons && persons.length > 0) {
        return persons.find(function (o) { return o.__id === id; });
    }
}
export function findOrganizationByID(id) {
    var x = get(ProjectMetadata).organizations;
    return x ? x.find(function (o) { return o.__id === id; }) : undefined;
}
export function findGrantByID(id) {
    var x = get(ProjectMetadata).grants;
    return x ? x.find(function (o) { return o.__id === id; }) : undefined;
}
export function findObjectByID(id) {
    var o;
    o = findPersonByID(id);
    if (o)
        return o;
    o = findOrganizationByID(id);
    if (o)
        return o;
    o = findGrantByID(id);
    if (o)
        return o;
}
export function getText(text, lang) {
    if (!text) {
        return '';
    }
    var langs = Object.keys(text);
    if (langs.length === 0) {
        return '';
    }
    else if (lang && langs.includes(lang)) {
        return text[lang];
    }
    else if (langs.includes('en')) {
        return text['en'];
    }
    else {
        return text[langs[0]];
    }
}
