export var setCookie = function (name, value, days, path) {
    if (days === void 0) { days = 90; }
    if (path === void 0) { path = '/'; }
    var domain = 'dasch.swiss';
    var expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie = name + "=" + encodeURIComponent(value) + "; domain=" + domain + "; expires=" + expires + "; path=" + path;
};
export var getCookie = function (name) {
    return document.cookie.split('; ').reduce(function (r, v) {
        var parts = v.split('=');
        return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, '');
};
var deleteCookie = function (name, path) {
    setCookie(name, '', -1, path);
};
