import { __awaiter, __generator } from "tslib";
import { navigate } from 'svelte-routing';
import { readable, writable } from 'svelte/store';
export var pagination = writable({});
export var pagedResults = writable(undefined);
export var projectMetadata = writable(undefined);
export var query = writable('');
export var previousRoute = writable('');
export var handleSnackbar = writable({ isSnackbar: false, message: '' });
export var statusFilter = writable({ showOngoing: true, showFinished: true });
export var isTestEnvironment = readable(window.location.hostname === 'localhost' ||
    window.location.hostname.startsWith('meta.test') ||
    window.location.hostname.startsWith('meta.dev'));
export function baseUrl() {
    var protocol = window.location.protocol;
    var port = protocol === 'https:' ? '' : ':3000';
    return protocol + "//" + window.location.hostname + port;
}
export function getProjectsMetadata(page, q) {
    return __awaiter(this, void 0, void 0, function () {
        var baseResultsRange, route, currentResultsRange;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    baseResultsRange = [1, 9];
                    currentResultsRange = baseResultsRange.map(function (v) { return v + (page - 1) * baseResultsRange[1]; });
                    if (q) {
                        query.set(q);
                        route = "projects?q=" + q + "&_page=" + page + "&_limit=" + baseResultsRange[1];
                        handleSnackbar.set({
                            isSnackbar: true,
                            message: "Displaying search results for query: " + q
                        });
                    }
                    else {
                        query.set('');
                        route = "projects?_page=" + page + "&_limit=" + baseResultsRange[1];
                    }
                    statusFilter.subscribe(function (f) {
                        if (!f.showFinished || !f.showOngoing) {
                            var filter = "&filter=" + (!f.showOngoing ? 'o' : '') + (!f.showFinished ? 'f' : '');
                            route += filter;
                        }
                    });
                    navigate("/" + route);
                    return [4, fetch(baseUrl() + "/api/v1/" + route)
                            .then(function (r) {
                            var totalCount = parseInt(r.headers.get('X-Total-Count'));
                            var totalPages = Math.floor(totalCount / baseResultsRange[1]);
                            if (!Number.isInteger(totalCount / baseResultsRange[1])) {
                                totalPages++;
                            }
                            pagination.set({
                                currentPage: page,
                                currentResultsRange: currentResultsRange,
                                totalCount: totalCount,
                                totalPages: totalPages
                            });
                            return r.json();
                        })
                            .then(function (data) {
                            pagedResults.set(data), console.log(data);
                        })];
                case 1:
                    _a.sent();
                    return [2];
            }
        });
    });
}
